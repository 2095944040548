<template>
  <div class="detail-container">
    <!-- 搜索框 -->
    <van-sticky>
      <div class="query">
        <van-field
          :center="true"
          v-model.trim="keyword"
          placeholder="请输入药品名称"
          @keyup.enter.native="handleQuery"
        />
      </div>
    </van-sticky>
    <!-- 药品详情 -->
    <van-loading size="24px" vertical color="#3663E0" v-show="loadingShow"
      >加载中...</van-loading
    >
    <div class="drug-detail" v-show="!loadingShow">
      <div class="drug-name">
        <div class="name">{{ drugDetail.name }}</div>
        <div class="english-name">{{ drugDetail.enname }}</div>
        <div class="english-name">
          本药品详情编审团队：中日友好医院药学部 <br/>
          更多内容详见「药先生」公众号
        </div>
        <!-- 收藏 -->
        <div
          v-show="!collect"
          @click="toggleCollect(drugDetail.id)"
          class="attention"
        >
          收藏药品
        </div>
        <div
          v-show="collect"
          @click="toggleCollect(drugDetail.id)"
          class="cancel attention"
        >
          已收藏药品
        </div>
      </div>
      <div class="drug-info">
        <div class="drug-info-item" v-if="drugDetail.spec">
          <div class="title">【常用规格】</div>
          <div class="info" v-html="drugDetail.spec"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.pregclass_info">
          <div class="title">【妊娠分级】{{ drugDetail.pregclass_info.name }}</div>
          <div class="info">{{ drugDetail.pregclass_info.desc }}</div>
        </div>   
        <div class="drug-info-item" v-if="drugDetail.lactclass_info">
          <div class="title">【哺乳分级】{{ drugDetail.lactclass_info.name }}</div>
          <div class="info">{{ drugDetail.lactclass_info.desc }}</div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.warn">
          <div class="title">【特别警示】</div>
          <div class="info" v-html="drugDetail.warn"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.effect">
          <div class="title">【作用特点/药理作用/抗菌特点】</div>
          <div class="info" v-html="drugDetail.effect"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.indic">
          <div class="title">【适应症】</div>
          <div class="info" v-html="drugDetail.indic"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.usage_content">
          <div class="title">【用法用量】</div>
          <div class="info" v-html="drugDetail.usage_content"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.adverse">
          <div class="title">【不良反应】</div>
          <div class="info" v-html="drugDetail.adverse"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.interact">
          <div class="title">【相互作用】</div>
          <div class="info" v-html="drugDetail.interact"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.incompa">
          <div class="title">【配伍禁忌】</div>
          <div class="info" v-html="drugDetail.incompa"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.tdm">
          <div class="title">【TDM】</div>
          <div class="info" v-html="drugDetail.tdm"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.pharma">
          <div class="title">【药代动力学】</div>
          <div class="info" v-html="drugDetail.pharma "></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.matters_content">
          <div class="title">【注意事项】</div>
          <div class="info" v-html="drugDetail.matters_content"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.caution">
          <div class="title">【慎用】</div>
          <div class="info" v-html="drugDetail.caution"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.contrain">
          <div class="title">【禁忌症】</div>
          <div class="info" v-html="drugDetail.contrain"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.monitor">
          <div class="title">【检测】</div>
          <div class="info" v-html="drugDetail.monitor"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.mededu">
          <div class="title">【用药教育/用药建议】</div>
          <div class="info" v-html="drugDetail.mededu"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.solution">
          <div class="title">【溶液配置】</div>
          <div class="info" v-html="drugDetail.solution"></div>
        </div>
        <div class="drug-info-item" v-if="drugDetail.solution">
          <div class="title">【用药教育/用药建议】</div>
          <div class="info" v-html="drugDetail.solution"></div>
        </div>
      </div>
    </div>
    <Attention ref="attention" />
    <Queries ref="queries" />
  </div>
</template>

<script>
import Attention from '@/components/Attention'
import Queries from '@/components/Queries'
import { searchShow } from '@/api/query'
import { collect } from '@/api/collect'
import { pregclass, lactclass } from '@/utils/level'


export default {
  data() {
    return {
      pregclass,
      lactclass,
      collect: 0, // 收藏/取消收藏
      keyword: '', // 搜索关键字
      drugDetail: {}, // 药品详情
      loadingShow: false,
      userInfo: {}
    }
  },
  components: { Attention, Queries },
  methods: {
    async toggleCollect(id) {
      // this.collect = !this.collect
      const { data } = await collect({ user_id: this.userInfo.id, drug_id: id })
      this.collect = Number(data)
    },
    // 查询药品
    handleQuery() {
      if (this.keyword === '') return this.Toast('请输入药品名称')
      this.$router.push({
        path: '/list',
        query: {
          keyword: this.keyword,
        }
      })
    },
    // 筛选妊娠分级
    pregFliter(data) {
      for (var i in pregclass) {
        if (pregclass[i].id === data) {
          return pregclass[i].value
        }
      }
    },
    // 筛选哺乳分级
    lactFilter(data) {
      for (var i in lactclass) {
        if (lactclass[i].id === data) {
          return lactclass[i].value
        }
      }
    },
    // 获取药品详情
    async getSearchShow() {
      try {
        this.loadingShow = true
        const id = this.$route.query.id
        const { data } = await searchShow({ id, user_id: this.userInfo.id })
        this.drugDetail = data
        this.collect = Number(data.is_collect)
      } catch (error) {
        console.log(error)
         if(error.response.data.msg == '请先关注') this.$refs.attention.attentionShow = true
      }
      this.loadingShow = false

    },


  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getSearchShow()
  }


}
</script>

<style lang="less" scoped>
.detail-container {
  .query {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    .van-cell {
      width: 341px;
      height: 48px;
      background: #f1f2f5;
      border-radius: 8px;
      &::before {
        content: "";
        width: 17px;
        height: 17px;
        background: url("../../assets/images/icon-search.png");
        background-size: 17px 17px;
        margin-right: 10px;
      }
    }
  }
  .drug-detail {
    padding: 0 20px;
    .drug-name {
      position: relative;
      box-sizing: border-box;
      padding-top: 30px;
      height: 130px;
      border-bottom: 1px solid #eee;
      .name {
        font-weight: 600;
        color: #333333;
        font-size: 21px;
      }
      .english-name {
        margin-top: 8px;
        color: #999999;
        font-size: 12px;
      }
      .attention {
        position: absolute;
        top: 32px;
        right: 0;
        border-radius: 4px;
        padding: 5px 7px;
        font-size: 12px;
        font-weight: 500;
        background-color: #4169e2;
        color: #fff;
      }
      .cancel {
        color: #999999;
        background-color: #f1f2f5;
      }
    }
    .drug-info {
      padding-bottom: 25px;
      .drug-info-item {
        color: #333;
        .title {
          padding: 25px 0 14px;
          margin-left: -10px;
          font-size: 18px;
        }
        .info {
          font-size: 14px;
        }
      }
    }
  }
  /deep/ .van-loading {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>