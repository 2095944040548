export const pregclass = [
  {
    id: 1,
    value: 'A级'
  },
  {
    id: 2,
    value: 'B级'
  },
  {
    id: 3,
    value: 'C级'
  },
  {
    id: 4,
    value: 'D级'
  },
  {
    id: 5,
    value: 'X级'
  },
]

export const lactclass = [
  {
    id: 1,
    value: 'L1'
  },
  {
    id: 2,
    value: 'L2'
  },
  {
    id: 3,
    value: 'L3'
  },
  {
    id: 4,
    value: 'L4'
  },
  {
    id: 5,
    value: 'L5'
  },
]
