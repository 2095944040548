import request from '@/utils/request'

// 药品列表
export function searchList(data) {
  return request({
    url: 'drug/searchList',
    method: 'post',
    data
  })
}

// 药品详情
export function searchShow(data) {
  return request({
    url: 'drug/searchShow',
    method: 'post',
    data
  })
}