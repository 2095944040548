import request from '@/utils/request'

// 收藏/取消收藏
export function collect(data) {
  return request({
    url: 'drug/collect',
    method: 'post',
    data
  })
}

// 收藏列表
export function getCollectList(data) {
  return request({
    url: 'drug/collectList',
    method: 'post',
    data
  })
}


